import React from 'react';

import config from '../../config';

export default function Footer() {
  return (
    <header id="header">
      <h1>{config.heading}</h1>
      <p style={{marginBottom: 0}}>We make it easy to take meaningful action on climate change by guiding you to the best low-carbon options for your home, car, and finances.</p>
      <p>Sign up to find out when we launch.</p>
    </header>
  );
}
