import React from 'react';

import Layout from '../components/Layout';
import Footer from '../components/Footer';
import Header from '../components/Header';
import EmailForm from '../components/EmailForm';
import SlideShow from '../components/SlideShow';

var settings = {
  images: [
    // { url: require('../assets/images/charlotte-stowe-h5ZOgVHrcno-unsplash.jpg'), position: 'center'},
    // { url: require('../assets/images/kai-gradert-07KxLPfH0l4-unsplash.jpg'), position: 'center'},
    { url: require('../assets/images/daniel-funes-fuentes-TyLw3IQALMs-unsplash.jpg'), position: 'center'},
    // { url: require('../assets/images/markus-spiske-d7FbDJkJSFw-unsplash.jpg'), position: 'center'},
    // { url: require('../assets/images/science-in-hd-ZNS6rizp9RU-unsplash.jpg'), position: 'center'},
    // { url: require('../assets/images/andreas-gucklhorn-Ilpf2eUPpUE-unsplash.jpg'), position: 'center'},
    // { url: require('../assets/images/tyler-casey-ficbiwfOPSo-unsplash.jpg'), position: 'center'},
    // { url: require('../assets/images/etienne-girardet-fti002hQCCA-unsplash.jpg'), position: 'center'},
    // { url: require('../assets/images/the-blowup-HeWrUlV5JSo-unsplash.jpg'), position: 'center'},
    // { url: require('../assets/images/markus-spiske-r1BS0pzlr1M-unsplash.jpg'), position: 'center'},
    // { url: require('../assets/images/nicholas-doherty-pONBhDyOFoM-unsplash.jpg'), position: 'center'},
    // { url: require('../assets/images/the-blowup-TDOQPhq631s-unsplash.jpg'), position: 'center'},
    // { url: require('../assets/images/joao-vitor-marcilio-aDyI7BnFWow-unsplash.jpg'), position: 'center'},
  ],
  // Delay.
  delay: 5000,
};

const IndexPage = () => (
  <Layout>
    <Header />
    <EmailForm />
    <Footer />
    <SlideShow settings={settings} />
  </Layout>
);

export default IndexPage;
