module.exports = {
  siteTitle: 'Reinventing', // <title>
  manifestName: 'Reinventing',
  manifestShortName: 'Reinventing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#216FFF',
  manifestThemeColor: '#216FFF',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/icon.png',
  pathPrefix: `/`, // This path is subpath of your hosting https://domain/portfolio
  // social
  heading: 'Reinventing',
  subHeading:
    "We make it easy to take meaningful action on climate change by guiding you to the best low-carbon options for your home, car, and finances. Sign up to find out when we launch.",
  socialLinks: [
    // {
    //   icon: 'fa-github',
    //   name: 'Github',
    //   url: 'https://github.com/anubhavsrivastava',
    // },
    // {
    //   icon: 'fa-twitter',
    //   name: 'Twitter',
    //   url: 'https://twitter.com/onlyanubhav',
    // },
    // {
    //   icon: 'fa-facebook',
    //   name: 'Facebook',
    //   url: 'https://facebook.com/theanubhav',
    // },
    // {
    //   icon: 'fa-envelope-o',
    //   name: 'Email',
    //   url: 'mailto:anubhav.srivastava00@gmail.com',
    // },
  ],
};
